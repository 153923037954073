import React from 'react'
import { Link } from '@reach/router'

import './style/terms-menu.scss'

let TermsMenu = () => {
  return (
    <div className='row-wrapper copy-right terms-menu'>
      <div className='container'>
        <div className='row'>
          <ul className='nav list-unstyled'>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms'>TERMS INDEX</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/terms-of-service'>TERMS OF SERVICE</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <a href='https://newfold.com/privacy-center' rel='noopener' target='_blank'>PRIVACY POLICY</a>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/acceptable-use'>ACCEPTABLE USE</Link>
            </li>
            <li className='col-xs-6 col-sm-3 active'>
              <Link to='/terms/trademark-disputes'>TRADEMARK DISPUTES</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/domain-registration-agreement'>DOMAIN REGISTRATION</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/copyright-infringement-policy'>COPYRIGHT</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/domain-renewal-notification-policy'>DNRN POLICY</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/data-request-policy'>DATA REQUEST POLICY</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/arbitration-agreement'>ARBITRATION AGREEMENT</Link>
            </li>
            <li className='col-xs-6 col-sm-3'>
              <Link to='/terms/professional-services-agreement'>PROFESSIONAL SERVICES AGREEMENT</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>)
}

export default TermsMenu
