import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProfessionalServiceAgreementPrevious from './../../components/professional-service-agreement-previous'
import TermsMenu from './../../components/terms-menu'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Terms Of Service'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'company_info'}
      flowType={'none'} />
    <TermsMenu />
    <ProfessionalServiceAgreementPrevious />
  </Layout>
)

export default IndexPage
